import { Shadows } from '@mui/material';

const shadow = {
    none: 'none',
    sm: `
         0px 0px 1px 0px rgba(0, 0, 0, 0.2),
         1px 1px 2px 0px rgba(0, 0, 0, 0.075),
         -1px 1px 2px 0px rgba(0, 0, 0, 0.075)
      `,
    md: `
         0px 3px 6px rgba(0, 0, 0, 0.07),
         0px 1.3px 2.5px rgba(0, 0, 0, 0.05),
         0px 0.7px 1.3px rgba(0, 0, 0, 0.04),
         0px 0.4px 0.8px rgba(0, 0, 0, 0.04),
         0px 0.2px 0.4px rgba(0, 0, 0, 0.03),
         0px 0.1px 0.2px rgba(0, 0, 0, 0.02)
      `,
    lg: `
         0px 13px 16px rgba(0, 0, 0, 0.05),
         0px 5.4px 6.7px rgba(0, 0, 0, 0.04),
         0px 2.9px 3.6px rgba(0, 0, 0, 0.03),
         0px 1.6px 2px rgba(0, 0, 0, 0.02),
         0px 0.9px 1.1px rgba(0, 0, 0, 0.02),
         0px 0.4px 0.4px rgba(0, 0, 0, 0.02)
      `,
    xl: `
         0px 26px 26px -5px rgba(0, 0, 0, 0.07),
         0px 10.9px 10.9px -5px rgba(0, 0, 0, 0.05),
         0px 5.8px 5.8px -5px rgba(0, 0, 0, 0.04),
         0px 3.3px 3.3px -5px rgba(0, 0, 0, 0.04),
         0px 1.7px 1.7px -5px rgba(0, 0, 0, 0.03),
         0px 0.7px 0.7px -5px rgba(0, 0, 0, 0.02)
      `,
};

export type ShadowTokens = keyof typeof shadow;
const values = Object.values(shadow);
const emptyValues = new Array<string>(20).fill('');
const shadows = [...values, ...emptyValues] as Shadows;
export { shadows };
