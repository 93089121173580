import type { ReactElement, PropsWithChildren } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import deepmerge from 'deepmerge';
import {
    createTheme,
    ThemeProvider as MuiThemeProvider,
    StyledEngineProvider,
    Theme,
} from '@mui/material';

import { muiTheme as defaultMuiTheme } from '@modules/ui/theme/mui-theme';
import { GlobalStyles } from '@modules/ui/theme';
import { defaultThemeConfig } from './helpers';

const ThemeProvider = ({ children }: PropsWithChildren): ReactElement => {
    const mergedTheme = deepmerge(defaultMuiTheme, defaultThemeConfig);
    const muiTheme = createTheme(mergedTheme, {});

    return (
        <StyledEngineProvider injectFirst>
            <GlobalStyles theme={muiTheme} />
            <MuiThemeProvider<Omit<Theme, 'shadows'>> theme={muiTheme}>
                <StyledThemeProvider theme={muiTheme}>{children}</StyledThemeProvider>
            </MuiThemeProvider>
        </StyledEngineProvider>
    );
};

export { ThemeProvider };
