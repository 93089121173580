import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { spacing } from '@modules/ui/tokens';
import { SpacingKeys } from '@modules/ui/tokens/spacing';

interface BoxProps extends PropsWithChildren {
    width?: string;
    height?: string;
    gap?: SpacingKeys;
}

export const BoxColumn = ({ width = '100%', gap = 'lg', height, children }: BoxProps) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            width: width,
            gap: spacing[gap],
            ...(height && { height: height }),
        }}
    >
        {children}
    </Box>
);

export const BoxRow = ({ width = '100%', gap = 'lg', height, children }: BoxProps) => (
    <Box
        sx={{ display: 'flex', width: width, gap: spacing[gap], ...(height && { height: height }) }}
    >
        {children}
    </Box>
);
