import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

type LoaderProps = {
    size?: number | string;
    initialHeight?: boolean;
    className?: string;
    padding?: string;
    transparent?: boolean;
};

const Root = styled.div<Pick<LoaderProps, 'initialHeight' | 'padding' | 'transparent'>>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${({ initialHeight }) => (initialHeight ? 'initial' : '100%')};
    padding: ${({ padding }) => padding || '16px'};
    background-color: ${({ transparent, theme }) =>
        transparent ? 'transparent' : theme.palette?.common?.white || '#ffffff'};
`;

const Loader = (props: LoaderProps) => {
    return (
        <Root {...props}>
            <CircularProgress size={props.size} />
        </Root>
    );
};

export { Loader };
