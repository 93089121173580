import { SystemHelper } from '@helpers';
import { ThemeOptions, Theme } from '@mui/material/styles/createTheme';

type LogoExt = 'png' | 'svg';

const isProduction = process.env.NODE_ENV === 'production';

const getCurrentTenant = (): string => {
    const [tenantWithEnv] = window.location.host.split('.');
    const [tenant] = tenantWithEnv.split('-');

    return tenant;
};

const getLogoPath = (ext: LogoExt = 'svg') =>
    isProduction ? `/theme/${getCurrentTenant()}/logo.${ext}` : `/images/dev-logo.${ext}`;

const getThemePath = () => `/theme/${getCurrentTenant()}/theme.json`;

const getFullLogoPath = (ext: LogoExt = 'png') => window.location.origin + getLogoPath(ext);
const getFullThemePath = () => window.location.origin + '/theme';

export const defaultThemeConfig: ThemeOptions = {
    settings: {
        adminToolUi: {
            title: 'ChoicePay Admin Tool',
            description: 'ChoicePay Admin Tool',
            copyright: '© %currentYear% ChoicePay. All rights reserved.',
        },
        memberUi: {
            title: 'ChoicePay - Payment Selection',
            description: 'ChoicePay - Payment Selection',
            choicepayThirdPartyDisclaimer:
                'Third-party product, logos, service and business names are trademarks/service marks of their respective owners.',
            copyright: '© %currentYear% Powered by ChoicePay. All rights reserved.',
        },
        contactEmail: 'noreply@choicepay.com',
    },
    customPalette: {
        sidebar: '#1b5630',
        backgroundColor: '#F4F6F8',
        logo: {
            maxWidth: 160,
        },
    },
    palette: {
        primary: {
            main: '#2B7434',
            dark: '#26682E',
            light: '#408148',
        },
        text: {
            primary: 'rgba(0,0,0,.87)',
            secondary: 'rgba(0,0,0,.6)',
        },
    },
};

const fetchTheme = async (): Promise<Theme> => {
    let theme = defaultThemeConfig as Theme;

    if (SystemHelper.isProd()) {
        const themeLink = window.location.origin + getThemePath();

        theme = await fetch(themeLink)
            .then<Theme>(response => response.json())
            .catch<Theme>(() => defaultThemeConfig as Theme)
            .then((theme: Theme) => theme);
    }

    return theme;
};

export {
    getCurrentTenant,
    getLogoPath,
    getThemePath,
    getFullThemePath,
    getFullLogoPath,
    fetchTheme,
};
