import { SystemHelper } from '@helpers';

enum ENVS {
    sand = 'sand',
    dev = 'dev',
    test = 'test',
    stage = 'stage',
    prod = 'prod',
}

const LOW_ENVS = [ENVS.sand, ENVS.dev, ENVS.test];

export const IS_PROD = process.env.NODE_ENV === 'production';
export const API_KEY = process.env.REACT_APP_API_KEY ?? '';
export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL ?? '';
const ASSET_PATH = IS_PROD ? process.env.REACT_APP_ASSET_PATH ?? '' : '';
const tenantId = window.location.href.split('/')[3];
const prefixName = SystemHelper.isProd() ? `/${tenantId}` : '';
export const BASENAME = prefixName + ASSET_PATH;

export const SOURCE_URL = process.env.REACT_APP_SOURCE_URL;

export const SESSION_EXPIRE_TIME = process.env.REACT_APP_SESSION_EXPIRE_TIME
    ? +process.env.REACT_APP_SESSION_EXPIRE_TIME
    : 30;
export const isLowLevelEnv = Boolean(
    process.env.REACT_APP_ENV && LOW_ENVS.includes(process.env.REACT_APP_ENV as ENVS),
);
