import { Palette, PaletteOptions } from '@mui/material/styles/createPalette';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { fontSize, fontWeight } from '@modules/ui/tokens';

export const typography = (palette: Palette | PaletteOptions): TypographyOptions => ({
    fontFamily: 'Inter',
    htmlFontSize: 16,
    fontSize: 16,
    body1: {
        fontSize: fontSize.md,
        fontWeight: fontWeight.regular,
        color: palette?.text?.primary,
        lineHeight: 1.5,
    },
    body2: {
        fontSize: fontSize.sm,
        fontWeight: fontWeight.regular,
        color: palette?.text?.primary,
        lineHeight: 1.43,
    },
    h1: {
        fontSize: fontSize.xl,
        fontWeight: fontWeight.semibold,
        color: palette?.text?.primary,
        lineHeight: 1.2,
    },
    h2: {
        fontSize: fontSize.lg,
        fontWeight: fontWeight.semibold,
        color: palette?.text?.primary,
        lineHeight: 1.2,
    },
    subtitle1: {
        fontSize: fontSize.sm,
        fontWeight: fontWeight.semibold,
        color: palette?.text?.secondary,
        lineHeight: 1.2,
    },
    subtitle2: {
        fontSize: fontSize.sm,
        textTransform: 'uppercase',
        fontWeight: fontWeight.semibold,
        color: palette?.text?.secondary,
        lineHeight: 1.2,
    },
    button: {
        fontSize: fontSize.md,
        fontWeight: fontWeight.semibold,
        lineHeight: 1.125,
    },
});
