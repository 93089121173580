import React from 'react';
import styled from 'styled-components';

import type { ButtonProps } from '@modules/ui/core';
import { Modal, Button, BoxColumn } from '@modules/ui/core';
import { fontSize, spacing } from '@modules/ui/tokens';
import { Typography } from '@mui/material';

export type InjectableChildrenProps = {
    open: boolean;
    handleOpen: () => void;
};

export type DialogProps = {
    title: string;
    open?: boolean;
    content?: React.ReactNode;
    disabled?: boolean;
    disabledAccept?: boolean;
    cancelText?: string;
    acceptText?: string;
    hideCancel?: boolean;
    children?: (props: InjectableChildrenProps) => React.ReactNode;
    onOpen?: () => void;
    onClose?: () => void;
    onCancel?: () => void;
    onAccept?: () => void | Promise<void>;
    CancelButtonProps?: ButtonProps;
    AcceptButtonProps?: ButtonProps;
};

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
`;

const Body = styled.div`
    width: 100%;
    color: ${({ theme }) => theme.palette.text.secondary};
`;

const Actions = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: ${spacing.sm};

    ${({ theme }) => theme.breakpoints.down('sm')} {
        & > * {
            width: calc(50% - 8px);
            font-size: ${fontSize.md};
            padding: 0 8px;
        }
    }
`;

const Dialog = (props: DialogProps): React.ReactElement => {
    const {
        open,
        title,
        content,
        disabled,
        disabledAccept,
        cancelText = 'Cancel',
        acceptText = 'Confirm',
        hideCancel,
        onOpen,
        onClose,
        onCancel,
        onAccept,
        children,
        CancelButtonProps,
        AcceptButtonProps,
    } = props;

    const [innerOpen, setInnerOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleOpen = (): void => (onOpen ? onOpen() : setInnerOpen(true));
    const handleClose = (): void => (onClose ? onClose() : setInnerOpen(false));

    const handleClickCancel = (): void => {
        handleClose();
        onCancel?.();
    };

    const handleClickAccept = async (): Promise<void> => {
        setLoading(true);
        await onAccept?.();
        setLoading(false);
        handleClose();
    };

    const resultOpen = typeof open !== 'undefined' ? open : innerOpen;
    const acceptButtonDisabled = disabled || disabledAccept;

    return (
        <>
            {children?.({ open: resultOpen, handleOpen })}

            <Modal hideCloseIcon open={resultOpen} onClose={handleClose}>
                <BoxColumn>
                    <BoxColumn>
                        <Header>
                            <Typography variant='h2' sx={{ wordBreak: 'break-all' }}>
                                {title}
                            </Typography>
                        </Header>

                        {content ? <Body>{content}</Body> : null}
                    </BoxColumn>
                    <Actions>
                        {hideCancel ? null : (
                            <Button
                                variant='outlined'
                                disabled={disabled}
                                onClick={handleClickCancel}
                                {...CancelButtonProps}
                            >
                                {cancelText}
                            </Button>
                        )}

                        <Button
                            disabled={acceptButtonDisabled}
                            loading={loading}
                            onClick={handleClickAccept}
                            {...AcceptButtonProps}
                        >
                            {acceptText}
                        </Button>
                    </Actions>
                </BoxColumn>
            </Modal>
        </>
    );
};

export { Dialog };
