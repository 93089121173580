import React from 'react';
import { rgba } from 'polished';
import type { TabProps as MuiTabProps } from '@mui/material';
import { Tab as MuiTab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { red } from '@modules/ui/colors';
import { concatenateClasses } from '@modules/ui/utils';

export type TabProps = MuiTabProps;

const useTabStyles = makeStyles({
    root: {
        minHeight: 40,
        padding: '0 24px',
        opacity: 1,

        '&:hover': {
            color: red[100],
            backgroundColor: 'transparent',
        },
    },
    textColorInherit: {
        '&$selected': {
            color: red[100],
            backgroundColor: rgba(red[100], 0.08),
        },
    },
    wrapper: {
        display: 'block',
        fontWeight: 400,
        textTransform: 'none',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    selected: {},
});

const Tab = (props: TabProps): React.ReactElement => {
    const { classes, ...otherProps } = props;

    const tabClasses = concatenateClasses(useTabStyles(), classes);

    return <MuiTab disableRipple classes={tabClasses} {...otherProps} />;
};

export { Tab };
