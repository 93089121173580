import React from 'react';
import type { LinkProps as MuiLinkProps } from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { concatenateClasses } from '@modules/ui/utils';

export type LinkProps = MuiLinkProps & {
    component?: React.ReactNode | any;
    to?: string;
};

const useLinkStyles = makeStyles({
    root: {
        cursor: 'pointer',
    },
});

const Link = (props: LinkProps) => {
    const { classes, ...otherProps } = props;

    const linkClasses = concatenateClasses(useLinkStyles(), classes);

    return <MuiLink classes={linkClasses} {...otherProps} />;
};

export { Link };
