import React from 'react';
import styled from 'styled-components';
import type { BreadcrumbsProps as MuiBreadcrumbsProps } from '@mui/material';
import { Link, Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { concatenateClasses } from '@modules/ui/utils';

export type Breadcrumb = {
    label: string;
    to?: string;
    current?: boolean;
    LinkComponent?: React.FunctionComponent<any>;
    LinkComponentProps?: Record<string, any>;
    onClick?: () => Promise<void> | void;
};

type BreadcrumbsProps = MuiBreadcrumbsProps & {
    breadcrumbs: Breadcrumb[];
};

const useBreadcrumbsStyles = makeStyles(theme => ({
    separator: {},
    li: {
        '& a': {
            color: theme.palette.text.secondary,

            '&:hover': {
                color: theme.palette.primary.main,
                textDecoration: 'none',
            },
        },
    },
}));

const Current = styled.p`
    color: ${({ theme }) => theme.palette.text.primary};
`;

const Breadcrumbs = (props: BreadcrumbsProps): React.ReactElement | null => {
    const { breadcrumbs, classes, separator = '›' } = props;

    const breadcrumbsClasses = concatenateClasses(useBreadcrumbsStyles(), classes);

    const hasBreadcrumbs = breadcrumbs.length !== 0;

    if (!hasBreadcrumbs) {
        return null;
    }

    return (
        <MuiBreadcrumbs classes={breadcrumbsClasses} separator={separator}>
            {breadcrumbs.map((breadcrumb, index) => {
                const {
                    label,
                    current,
                    LinkComponent = Link,
                    LinkComponentProps,
                    ...otherBreadcrumb
                } = breadcrumb;

                if (current) {
                    return <Current key={index}>{label}</Current>;
                }

                return (
                    <LinkComponent
                        color='inherit'
                        key={index}
                        href={otherBreadcrumb.to}
                        {...otherBreadcrumb}
                        {...LinkComponentProps}
                    >
                        {label}
                    </LinkComponent>
                );
            })}
        </MuiBreadcrumbs>
    );
};

export { Breadcrumbs };
