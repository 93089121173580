import React, { ReactElement } from 'react';

type NotFoundTenantErrorBoundaryProps = {
    children: ReactElement;
};

type NotFoundTenantErrorBoundaryState = { hasError: boolean };

class NotFoundTenantErrorBoundary extends React.Component<
    NotFoundTenantErrorBoundaryProps,
    NotFoundTenantErrorBoundaryState
> {
    constructor(props: NotFoundTenantErrorBoundaryProps) {
        super(props);

        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error) {
        console.error(error);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

export { NotFoundTenantErrorBoundary };
