import { PaletteOptions, PaletteColorOptions } from '@mui/material/styles/createPalette';

export interface ExtPaletteOptions {
    info2?: PaletteColorOptions;
    info3?: PaletteColorOptions;
    info4?: PaletteColorOptions;
}

export type Palette = PaletteOptions & ExtPaletteOptions;

export const palette: Palette = {
    text: {
        primary: '#212B36',
        secondary: '#637381',
        disabled: '#BABABA',
    },
    primary: {
        main: '#004DE5',
        light: '#3377FF',
        dark: '#002B80',
        chips: '#D1DFFA',
    },
    error: {
        main: '#C91D1D',
        light: '#DA0B0B',
        dark: '#BA1414',
        chips: '#F5D6D6',
    },
    warning: {
        main: '#E59900',
        light: '#FFBB33',
        dark: '#B27700',
        chips: '#FAEDD1',
    },
    info: {
        main: '#0086E5',
        light: '#33AAFF',
        dark: '#0068B2',
        chips: '#D1E9FA',
    },
    success: {
        main: '#36BD28',
        light: '#23D411',
        dark: '#49A63F',
        chips: '#DAF4D7',
    },
    info2: {
        main: '#4D88FF',
        light: '#80AAFF',
        dark: '#0044CC',
        chips: '#D1DFFA',
    },
    info3: {
        main: '#9B51E0',
        light: '#C193EC',
        dark: '#681FAD',
        chips: '#E6D4F7',
    },
    info4: {
        main: '#4FA7D9',
        light: '#82C0E3',
        dark: '#1C597D',
        chips: '#BEE2F6',
    },
    common: {
        white: '#ffffff',
        black: '#000000',
    },
};
