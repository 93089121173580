import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle<{ theme?: Record<string, any> }>`
    * {
        margin: 0;
        padding: 0;
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }

    html {
        width: 100%;
        font-size: ${p => (p.theme ? `${p.theme.typography.htmlFontSize}px` : '16px')};
        font-family: 'Inter', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        -webkit-font-smoothing: antialiased;
    }

    body {
        width: 100%;
        color: ${p => (p.theme ? p.theme.palette.text.primary : 'black')};
        font-weight: ${p => (p.theme ? p.theme.typography.fontWeightMedium : 500)};
        background-color: ${p => (p.theme ? p.theme.customPalette.backgroundColor : 'white')};
        
        .wrapper-img {
          line-height: 1;
        }

        &.modal-lock {
            padding-right: 16px;
            overflow: hidden;
        }
    }

    #root {
        width: 100%;
    }
    
    a {
        text-decoration: none;
        transition: all .2s linear;
    }

    ul, ol {
        list-style: none;
    }
`;

export { GlobalStyles };
