import React from 'react';
import { format, parse, isValid } from 'date-fns';
import { DateTextMask, placeholderChar } from '../datepicker-text-field';

export type UseDateInputProps = {
    date: Date | null;
    format: string;
    onDateChange: (date: Date) => void;
    onResetDate: () => void;
    onBlur?: (e: any) => void;
};

export type UseDateInputReturn = {
    value: string;
    onChange: (event: React.ChangeEvent<any>) => void;
    onFocus: () => void;
    onBlur: (e: any) => void;
    inputComponent: (props: any) => React.ReactElement;
    inputProps: {
        dateFormat: string;
    };
};

const regExpPlaceholderChar = new RegExp(placeholderChar, 'g');

const useDateInput = (props: UseDateInputProps): UseDateInputReturn => {
    const { date: selectedDate, format: formatStringProp, onDateChange, onResetDate } = props;

    const formatDate = (date: Date | number) => format(date, formatStringProp);

    const parseDate = (dateString: string) =>
        parse(dateString, formatStringProp, selectedDate || new Date());

    const isValidDate = (date: any) => isValid(date);

    const [value, setValue] = React.useState<string>('');
    const [focused, setFocused] = React.useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<any>) => {
        const eventValue = event.target.value;
        const clearEventValue = eventValue.replace(regExpPlaceholderChar, '');

        const parsedDate = parseDate(eventValue);

        setValue(eventValue);

        if (isValidDate(parsedDate) && clearEventValue.length === formatStringProp.length) {
            onDateChange(parsedDate);
        }

        if (!eventValue) {
            onResetDate();
        }
    };

    const handleFocus = () => setFocused(true);

    const handleBlur = () => {
        if (value) {
            const parsedDate = parseDate(value);

            if (isValidDate(parsedDate)) {
                setValue(formatDate(parsedDate));
            } else if (selectedDate && isValidDate(selectedDate)) {
                setValue(formatDate(selectedDate));
            } else {
                setValue('');
            }
        } else if (selectedDate) {
            onResetDate();
        }

        setFocused(false);
    };

    React.useEffect(() => {
        if (!focused) {
            const validDate =
                selectedDate && isValidDate(selectedDate) ? formatDate(selectedDate) : '';

            setValue(validDate);
        }
    }, [focused, selectedDate]);

    return {
        value,
        onChange: handleChange,
        onFocus: handleFocus,
        onBlur: handleBlur,
        inputComponent: DateTextMask,
        inputProps: {
            dateFormat: formatStringProp,
        },
    };
};

export { useDateInput };
