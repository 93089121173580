import React from 'react';
import styled from 'styled-components';
import { FallbackProps } from 'react-error-boundary';
import { IconButton, Typography } from '@mui/material';
import { Replay, SentimentDissatisfied } from '@mui/icons-material/';

import { GlobalStyles } from '@modules/ui/theme';
import { fontSize } from '@modules/ui/tokens';
import { ErrorPage } from '@lib/errors';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - 50px);

    Button {
        font-size: ${fontSize.md};
        background-color: lightgray;
    }
`;

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
    return (
        <Container>
            <GlobalStyles />
            <Typography variant='h1' component='div' align='center'>
                <SentimentDissatisfied fontSize='inherit' />
            </Typography>
            <ErrorPage
                title='Something went wrong!'
                message={error.toString()}
                fullHeight={false}
                size='small'
            />
            <br />
            <IconButton onClick={resetErrorBoundary}>
                <Replay />
            </IconButton>
        </Container>
    );
};

export { ErrorFallback };
