import React from 'react';
import type { TabsProps as MuiTabsProps } from '@mui/material';
import { Tabs as MuiTabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { white } from '@modules/ui/colors';
import { concatenateClasses } from '@modules/ui/utils';

export type TabsProps = Omit<MuiTabsProps, 'onChange'> & {
    onChange?: (event: React.ChangeEvent<{}>, value: any) => void;
};

const useTabsStyles = makeStyles({
    flexContainer: {
        height: '100%',
    },

    vertical: {
        '& $scroller': {
            flex: 'none',
            borderRight: `1px solid ${white[80]}`,
        },
        '& $indicator': {
            width: 3,
        },
    },

    scroller: {},
    indicator: {},
});

const Tabs = (props: TabsProps): React.ReactElement => {
    const { classes, ...otherProps } = props;

    const tabsClasses = concatenateClasses(useTabsStyles(), classes);

    return (
        <MuiTabs variant='scrollable' scrollButtons='auto' classes={tabsClasses} {...otherProps} />
    );
};

export { Tabs };
