import React from 'react';
import { ThemeOptions } from '@mui/material/styles/createTheme';

import { typography } from './typography';
import { palette } from './palette';
import { components } from './components';
import { spacing, fontSize, fontWeight, shadows, borderRadius } from '../tokens';
import { SpacingKeys } from '@modules/ui/tokens/spacing';

const muiTheme: ThemeOptions = {
    spacing: (factor: SpacingKeys) => spacing[factor],
    typography,
    palette,
    components,
    customPalette: {},
    fontSize,
    fontWeight,
    shadows,
    borderRadius,
};

export { muiTheme };
