import React, { lazy, Suspense, useEffect } from 'react';
import styled from 'styled-components';
import { ApolloProvider } from '@apollo/client';

import { createApolloClient } from '@lib/apollo';
import { useCurrentTenant } from '@modules/shared/hooks';
import { NotFoundTenantErrorBoundary } from '@modules/shared/organisms';
import { Loader } from '@modules/layout/moleculas';
import { ChoicePayHomePage } from './modules/shared/organisms';
import { PageNotFound } from '@lib/errors';
import { ThemeProvider } from '@modules/shared/theme-provider';

const AbDataApp = lazy(() => import('./applications/abdata/app'));
const AdminApp = lazy(() => import('./applications/admin/app'));
const ClearScaleApp = lazy(() => import('./applications/clearscale/app'));
const EpiqApp = lazy(() => import('./applications/epiq/app'));
const DigitalToranaApp = lazy(() => import('./applications/digitaltorana/app'));
const MorleyApp = lazy(() => import('./applications/morley/app'));
const UtoledoApp = lazy(() => import('./applications/utoledo/app'));
const PaymentsApp = lazy(() => import('./applications/payments/app'));
const MtunionApp = lazy(() => import('./applications/mtunion/app'));
const LowellLightApp = lazy(() => import('./applications/lowell-light/app'));
const LowellLight = lazy(() => import('./applications/lowelllight/app'));
const LowellPayments = lazy(() => import('./applications/lowellpayments/app'));
const HomflatsApp = lazy(() => import('./applications/homflats/app'));
const LumbermensApp = lazy(() => import('./applications/lumbermens/app'));
const ArcherApp = lazy(() => import('./applications/archer/app'));
const UAT1App = lazy(() => import('./applications/uat1/app'));
const UAT2App = lazy(() => import('./applications/uat2/app'));
const UAT3App = lazy(() => import('./applications/uat3/app'));
const SheaautomotivegroupApp = lazy(() => import('./applications/sheaautomotivegroup/app'));
const CcadApp = lazy(() => import('./applications/ccad/app'));
const FoxpaymentsApp = lazy(() => import('./applications/foxpayments/app'));
const KaftanEnterprisesApp = lazy(() => import('./applications/kaftanenterprises/app'));
const CcsohApp = lazy(() => import('./applications/ccsoh/app'));
const BeckfieldApp = lazy(() => import('./applications/beckfield/app'));
const RooseveltparkApp = lazy(() => import('./applications/rooseveltpark/app'));
const RisebrandsApp = lazy(() => import('./applications/risebrands/app'));
const WaynestateApp = lazy(() => import('./applications/waynestate/app'));
const CoastalridgeApp = lazy(() => import('./applications/coastalridge/app'));
const MktruckcentersApp = lazy(() => import('./applications/mktruckcenters/app'));
const ClarkstoncsdApp = lazy(() => import('./applications/clarkstoncsd/app'));
const McdonaldfordApp = lazy(() => import('./applications/mcdonaldford/app'));
const MajormetalsApp = lazy(() => import('./applications/majormetals/app'));
const MorleycompaniesApp = lazy(() => import('./applications/morleycompanies/app'));
const BeachexpressApp = lazy(() => import('./applications/beachexpress/app'));
const StrategicclaimsApp = lazy(() => import('./applications/strategicclaims/app'));
const AngeiongroupApp = lazy(() => import('./applications/angeiongroup/app'));
const SimplurisApp = lazy(() => import('./applications/simpluris/app'));
const AltatrustApp = lazy(() => import('./applications/altatrust/app'));
const HollandBPWApp = lazy(() => import('./applications/hollandbpw/app'));
const NewMoveInRewardsApp = lazy(() => import('./applications/newmoveinrewards/app'));
const SalzburgCapitalApp = lazy(() => import('./applications/salzburgcapital/app'));
const SettleGuardApp = lazy(() => import('./applications/settleguard/app'));
const PorscheApp = lazy(() => import('./applications/porsche/app'));
const CatholicCharitiesApp = lazy(() => import('./applications/catholiccharities/app'));
const RustconsultingApp = lazy(() => import('./applications/rustconsulting/app'));

const TenantApp = {
    abdata: AbDataApp,
    admin: AdminApp,
    clearscale: ClearScaleApp,
    epiq: EpiqApp,
    digitaltorana: DigitalToranaApp,
    morley: MorleyApp,
    utoledo: UtoledoApp,
    payments: PaymentsApp,
    mtunion: MtunionApp,
    'lowell-light': LowellLightApp,
    lowelllight: LowellLight,
    homflats: HomflatsApp,
    lumbermens: LumbermensApp,
    archer: ArcherApp,
    uat1: UAT1App,
    uat2: UAT2App,
    uat3: UAT3App,
    sheaautomotivegroup: SheaautomotivegroupApp,
    ccad: CcadApp,
    lowellpayments: LowellPayments,
    foxpayments: FoxpaymentsApp,
    kaftanenterprises: KaftanEnterprisesApp,
    ccsoh: CcsohApp,
    beckfield: BeckfieldApp,
    rooseveltpark: RooseveltparkApp,
    risebrands: RisebrandsApp,
    waynestate: WaynestateApp,
    coastalridge: CoastalridgeApp,
    mktruckcenters: MktruckcentersApp,
    clarkstoncsd: ClarkstoncsdApp,
    mcdonaldford: McdonaldfordApp,
    majormetals: MajormetalsApp,
    morleycompanies: MorleycompaniesApp,
    beachexpress: BeachexpressApp,
    strategicclaims: StrategicclaimsApp,
    angeiongroup: AngeiongroupApp,
    simpluris: SimplurisApp,
    altatrust: AltatrustApp,
    hollandbpw: HollandBPWApp,
    newmoveinrewards: NewMoveInRewardsApp,
    salzburgcapital: SalzburgCapitalApp,
    settleguard: SettleGuardApp,
    porsche: PorscheApp,
    catholiccharities: CatholicCharitiesApp,
    rustconsulting: RustconsultingApp,
};

const apolloClient = createApolloClient({});

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
`;

const TenantApplication = (): React.ReactElement => {
    const { currentTenant, loading: currentTenantLoading } = useCurrentTenant();
    const isTenantIdExist = window.location.href.split('/').filter(Boolean).length > 2;

    useEffect(() => {
        if (!isTenantIdExist) {
            document.title = 'Choicepay';
        }
    }, []);

    if (currentTenantLoading) {
        return (
            <Root>
                <Loader />
            </Root>
        );
    }

    if (!currentTenant && !isTenantIdExist) {
        return <ChoicePayHomePage />;
    }

    if (!currentTenant) {
        return (
            <ThemeProvider>
                <PageNotFound />
            </ThemeProvider>
        );
    }

    const TenantHomeApp = TenantApp[currentTenant.id];
    const basename = `/${currentTenant.id}`;

    return (
        <Suspense fallback={<Loader />}>
            <TenantHomeApp basename={basename} />
        </Suspense>
    );
};

const App = (): React.ReactElement => {
    return (
        <ApolloProvider client={apolloClient}>
            <NotFoundTenantErrorBoundary>
                <TenantApplication />
            </NotFoundTenantErrorBoundary>
        </ApolloProvider>
    );
};

export { App };
