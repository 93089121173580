const fontSize = {
    xs: '0.625rem',
    sm: '0.812rem', // body 2 | subtitle
    md: '1rem', // body1
    lg: '1.125rem', // h2
    xl: '1.625rem', // h1
};

export type FontSizeTokens = keyof typeof fontSize;

export { fontSize };
