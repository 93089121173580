import React, { useRef, PropsWithChildren } from 'react';
import { SnackbarKey, SnackbarProvider, SnackbarOrigin } from 'notistack';
import styled from 'styled-components';
import { withStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import { SnackbarUtilsConfigurator } from '@lib/errors';
import { Close } from '@mui/icons-material';

const SnackbarProviderStyled = styled(SnackbarProvider)`
    &.SnackbarItem-contentRoot {
        white-space: pre-line;
    }
`;

const CloseIconButton = withStyles(theme => ({
    root: {
        width: 20,
        height: 20,
        padding: 0,
        color: theme.palette.common.white,
    },
}))(IconButton);

const anchorOrigin: SnackbarOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
};

const closeIcon = <Close style={{ fontSize: 22 }} />;

export const SnackBarProvider = ({ children }: PropsWithChildren) => {
    const notistackRef = useRef<any | null>(null);

    const onClickDismiss = (key: SnackbarKey) => (): void => {
        if (notistackRef.current) {
            notistackRef.current.closeSnackbar(key);
        }
    };

    const handleCloseAction = (key: SnackbarKey) => (
        <CloseIconButton onClick={onClickDismiss(key)}>{closeIcon}</CloseIconButton>
    );

    return (
        <SnackbarProviderStyled
            dense
            maxSnack={3}
            autoHideDuration={10000}
            ref={notistackRef}
            action={handleCloseAction}
            anchorOrigin={anchorOrigin}
        >
            <SnackbarUtilsConfigurator />
            {children}
        </SnackbarProviderStyled>
    );
};
