import React from 'react';
import styled, { css } from 'styled-components';

import { DatepickerContext } from '../context';
import { useModifyDay } from '../hooks';
import type { GetDayVariantReturn } from './get-day-variant';
import { getDayVariant } from './get-day-variant';
import { grey } from '@modules/ui/colors';

type DayProps = {
    date: Date;
    label?: string;
};

const Root = styled.button<{ today?: boolean; variant?: GetDayVariantReturn }>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 100%;
    letter-spacing: 0.25px;
    border: none;
    border-radius: 4px;
    transition: all 0.2s;
    outline: none;
    cursor: pointer;

    > p {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
    }

    ${p =>
        !!p.today &&
        css`
            &:before {
                content: '';
                display: block;
                position: absolute;
                left: calc(50% - 2px);
                bottom: 4px;
                width: 4px;
                height: 4px;
                background-color: ${({ theme }) => theme.palette.primary.main};
                border-radius: 100%;
            }
        `}

    ${p =>
        (p.variant === 'normal' &&
            css`
                color: ${({ theme }) => theme.palette.text.primary};
                background-color: ${({ theme }) => theme.palette.background.paper};

                &:hover {
                    color: ${({ theme }) => theme.palette.background.paper};
                    background-color: ${({ theme }) => theme.palette.primary.main};
                }
            `) ||
        (p.variant === 'selected' &&
            css`
                background-color: black;
                border-radius: 0;
            `) ||
        (p.variant === 'disabled' &&
            css`
                color: ${grey.light};
                background-color: ${({ theme }) => theme.palette.background.paper};
                opacity: 0.6;
            `) ||
        (p.variant === 'first-selected' &&
            css`
                color: ${({ theme }) => theme.palette.background.paper};
                background-color: ${({ theme }) => theme.palette.primary.main};
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                &:before {
                    background-color: ${({ theme }) => theme.palette.background.paper};
                }
            `) ||
        (p.variant === 'last-selected' &&
            css`
                color: ${({ theme }) => theme.palette.background.paper};
                background-color: ${({ theme }) => theme.palette.primary.main};
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                &:before {
                    background-color: ${({ theme }) => theme.palette.background.paper};
                }
            `) ||
        (p.variant === 'solo-selected' &&
            css`
                color: ${({ theme }) => theme.palette.background.paper};
                background-color: ${({ theme }) => theme.palette.primary.main};

                &:before {
                    background-color: ${({ theme }) => theme.palette.background.paper};
                }
            `) ||
        (p.variant === 'range' &&
            css`
                background-color: black;
                border-radius: 0;
            `)}
`;

const Day = (props: DayProps): React.ReactElement => {
    const { date, label } = props;

    const dayRef = React.useRef(null);

    const {
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isStartEqualLastDate,
        isLastSelectedDate,
        isStartSelectedDate,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
    } = React.useContext(DatepickerContext);

    const {
        isToday,
        isStartEqualLast,
        isSelectedStart,
        isSelectedLast,
        isSelected,
        isWithinHoverRange,
        disabledDate,
        onClick,
        onKeyDown,
        onMouseEnter,
    } = useModifyDay({
        date,
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isStartEqualLastDate,
        isStartSelectedDate,
        isLastSelectedDate,
        isFirstOrLastSelectedDate,
        onDateFocus,
        onDateSelect,
        onDateHover,
        dayRef,
    });

    const variant = getDayVariant({
        isSelected,
        isSelectedStart,
        isSelectedLast,
        isStartEqualLast,
        isWithinHoverRange,
        isDisabled: disabledDate,
    });

    if (!label) {
        return <Root />;
    }

    return (
        <Root
            type='button'
            today={isToday}
            variant={variant}
            ref={dayRef}
            onClick={onClick}
            onKeyDown={onKeyDown}
            onMouseEnter={onMouseEnter}
        >
            <p>{label}</p>
        </Root>
    );
};

export { Day };
