import React from 'react';
import styled from 'styled-components';
import type { InputAdornmentProps } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { InsertInvitation } from '@mui/icons-material';

import type { TextFieldProps } from '@modules/ui/core';
import { TextField } from '@modules/ui/core';
import { fontSize } from '@modules/ui/tokens';

type DatepickerTextFieldProps = TextFieldProps & {
    IconProps?: InputAdornmentProps;
    dateFormat?: string;
};

const CalendarIcon = styled(InsertInvitation)<{ disabled: boolean }>`
    ${p => !p.disabled && `color: ${p.theme.palette.primary.main};`}
    font-size: ${fontSize.xl};
`;

const noop = () => ({});

const DatepickerTextField = React.forwardRef<HTMLDivElement, DatepickerTextFieldProps>(
    (props, ref) => {
        const { disabled, onClick, IconProps, InputProps, inputProps, ...otherProps } = props;

        const handleClick = disabled ? noop : onClick;

        return (
            <TextField
                fullWidth
                autoComplete='off'
                ref={ref}
                disabled={disabled}
                onClick={handleClick}
                placeholder={inputProps?.dateFormat || ''}
                InputProps={{
                    endAdornment: (
                        <>
                            {InputProps?.endAdornment}

                            <InputAdornment {...IconProps} position='end'>
                                <CalendarIcon disabled={!!disabled} />
                            </InputAdornment>
                        </>
                    ),
                }}
                {...otherProps}
            />
        );
    },
);

export { DatepickerTextField };
