import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import { API_KEY, GRAPHQL_URL, SOURCE_URL } from '@config/environment';
import { SystemHelper } from '@helpers';

import type {
    ApolloClientOptions as BaseApolloClientOptions,
    NormalizedCacheObject,
} from '@apollo/client';

type ApolloClientOptions = Omit<BaseApolloClientOptions<NormalizedCacheObject>, 'cache'>;

const isDev = SystemHelper.isDev();

const xSourceURL = 'x-source-url';
const X_API_KEY = 'X-API-KEY';

const createApolloClient = (options: ApolloClientOptions) => {
    const httpLink = new HttpLink({ uri: GRAPHQL_URL });

    const authHttpLink = setContext((_, prevContext) => {
        const getSourceURL = () => (SystemHelper.isProd() ? window.location.href : SOURCE_URL);

        const context = {
            ...prevContext,
            headers: {
                ...prevContext.headers,
                [X_API_KEY]: API_KEY,
                [xSourceURL]: getSourceURL(),
            },
        };

        return context;
    });

    const errorLink = onError(payload => {
        const { graphQLErrors = [] } = payload;

        graphQLErrors.forEach((error: Record<string, any>) => {
            // const errorType = error?.errorType;
        });
    });

    const link = ApolloLink.from([authHttpLink, errorLink, httpLink]);

    const apolloClient = new ApolloClient({
        link,
        cache: new InMemoryCache(),
        connectToDevTools: isDev,
        ...options,
    });

    return apolloClient;
};

export { createApolloClient };
