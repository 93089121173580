import React from 'react';
import styled from 'styled-components';
import type { PaperProps, PopperProps } from '@mui/material';
import { ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { concatenateClasses } from '@modules/ui/utils';
import type { OnDateChangeProps } from '@modules/ui/core';
import { DatepickerSingle } from '@modules/ui/core';
import type { UseDateInputReturn } from './hooks';
import { useDateInput } from './hooks';

type InjectableChildrenProps = {
    open: boolean;
    inputComponent: (props: any) => React.ReactElement;
    inputProps: Omit<UseDateInputReturn, 'inputComponent'> & {
        inputRef: React.MutableRefObject<any>;
        onClick: () => void;
    };
};

type DatepickerSinglePickerProps = {
    date: Date | null;
    children: (props: InjectableChildrenProps) => React.ReactElement;
    onDateChange: (options: OnDateChangeProps) => void;
    format?: string;
    className?: string;
    minBookingDate?: Date;
    maxBookingDate?: Date;
    unavailableDates?: Date[];
    initialVisibleMonth?: Date;
    PopperProps?: PopperProps;
    PaperProps?: PaperProps;
    onSelect?: (date: Date | null) => void;
    onBlur?: (e: any) => void;
};

const usePaperStyles = makeStyles({
    root: {
        marginTop: 4,
        boxShadow: 'none',
    },
});

const Root = styled.div`
    position: relative;
`;

const DatepickerSinglePicker = (props: DatepickerSinglePickerProps): React.ReactElement => {
    const {
        date,
        format = 'dd.MM.yyyy',
        initialVisibleMonth,
        className,
        children,
        onSelect,
        onBlur,
        onDateChange: onDateChangeProp,
        PopperProps,
        PaperProps,
        ...otherProps
    } = props;

    const datepickerRef = React.useRef<any>();
    const inputRef = React.useRef<any>();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const paperClasses = concatenateClasses(usePaperStyles(), PaperProps?.classes);

    const handleChangeInput = (changedDate: Date) => {
        if (datepickerRef?.current?.onDateSelect) {
            datepickerRef.current.onDateSelect(changedDate);
        }
    };

    const handleResetDates = () => {
        if (datepickerRef?.current?.onDateSelect) {
            datepickerRef.current.onResetDates();
        }
    };

    const onDateChange = (data: OnDateChangeProps) => {
        onDateChangeProp({ date: data.date });

        setAnchorEl(null);

        onSelect?.(data.date);

        if (inputRef?.current) {
            inputRef.current.blur();
            setTimeout(() => onBlur?.({ target: {} }), 0);
        }
    };

    const open = !!anchorEl;

    const { inputComponent, ...inputProps } = useDateInput({
        date,
        format,
        onDateChange: handleChangeInput,
        onResetDate: handleResetDates,
        onBlur,
    });

    React.useEffect(() => {
        if (initialVisibleMonth) {
            datepickerRef.current.goToDate(initialVisibleMonth);
        }
    }, [initialVisibleMonth]);

    return (
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Root className={className}>
                {children({
                    open,
                    inputComponent,
                    inputProps: {
                        ...inputProps,
                        inputRef,
                        onClick: () => setAnchorEl(inputRef.current),
                        onFocus: () => inputProps.onFocus(),
                    },
                })}

                <Popper
                    transition
                    disablePortal
                    keepMounted
                    placement='bottom-start'
                    open={open}
                    anchorEl={anchorEl}
                    {...PopperProps}
                    // modifiers={{
                    //     checkOverlapWindow: {
                    //         order: 0,
                    //         enabled: true,
                    //         fn: (data: Record<string, any>) => {
                    //             const { instance } = data;
                    //
                    //             const referenceRect = instance.reference.getBoundingClientRect();
                    //
                    //             const popperWidth = instance.popper.children[0]
                    //                 ? instance.popper.children[0].scrollWidth
                    //                 : instance.popper.scrollWidth;
                    //
                    //             const windowWidth = window.innerWidth;
                    //             const widthToOverlap = referenceRect.left + popperWidth;
                    //
                    //             if (widthToOverlap > windowWidth) {
                    //                 data.styles.left = referenceRect.right - windowWidth;
                    //             }
                    //
                    //             return data;
                    //         },
                    //     },
                    //     ...PopperProps?.modifiers,
                    // }}
                    style={{ zIndex: 1000, ...PopperProps?.style }}
                >
                    {popperProps => {
                        const { TransitionProps } = popperProps;

                        return (
                            <Grow
                                {...TransitionProps}
                                style={{ position: 'absolute', transformOrigin: '0 0 0' }}
                                timeout={150}
                            >
                                <Paper {...PaperProps} classes={paperClasses}>
                                    <DatepickerSingle
                                        ref={datepickerRef}
                                        date={date}
                                        onDateChange={onDateChange}
                                        {...otherProps}
                                    />
                                </Paper>
                            </Grow>
                        );
                    }}
                </Popper>
            </Root>
        </ClickAwayListener>
    );
};

export { DatepickerSinglePicker };
