import React from 'react';
import styled, { css } from 'styled-components';
import type { Theme, ButtonProps as MuiButtonProps } from '@mui/material';
import { Button as MuiButton, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { white } from '@modules/ui/colors';
import { concatenateClasses } from '@modules/ui/utils';

export type ButtonProps = Omit<MuiButtonProps, 'size'> & {
    size?: 'extra-small' | 'small' | 'medium' | 'large';
    loading?: boolean;
};

const heightBySizeMap = {
    'extra-small': 32,
    small: 40,
    medium: 48,
    large: 56,
};

const useButtonStyles = makeStyles<
    Theme,
    { size?: NonNullable<ButtonProps['size']>; loading: ButtonProps['loading'] }
>({
    root: p => ({
        height: p.size ? heightBySizeMap[p.size] : 'initial',
        pointerEvents: !!p.loading ? 'none' : 'unset',
        textTransform: 'unset',
    }),
});

const useCircularProgressStyles = makeStyles({
    root: {
        '&$colorPrimary': {
            color: 'inherit',
        },
        '&$colorSecondary': {
            color: 'inherit',
        },
    },
    colorPrimary: {},
    colorSecondary: {},
});

const LoadingWrap = styled.span<
    Pick<ButtonProps, 'variant'> & { color: NonNullable<ButtonProps['color']> }
>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px;

    ${p =>
        (p.variant === 'contained' &&
            css`
                color: ${white[100]};
                background-color: ${['primary', 'default'].includes(p.color)
                    ? p.theme.palette.primary.main
                    : p.theme.palette.secondary.main};
            `) ||
        (p.variant === 'outlined' &&
            css`
                color: ${['primary', 'default'].includes(p.color)
                    ? p.theme.palette.primary.main
                    : p.theme.palette.secondary.main};
                background-color: ${white[100]};
            `)}
`;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props, ref): React.ReactElement => {
        const {
            variant = 'contained',
            color = 'primary',
            size = 'small',
            disabled,
            loading,
            classes,
            children,
            ...otherProps
        } = props;

        const buttonClasses = concatenateClasses(useButtonStyles({ loading }), classes);
        const circularProgressClasses = useCircularProgressStyles();

        return (
            <MuiButton
                ref={ref}
                variant={variant}
                color={color}
                classes={buttonClasses}
                disabled={disabled || loading}
                {...otherProps}
            >
                {loading ? (
                    <LoadingWrap variant={variant} color={color}>
                        <CircularProgress size={20} classes={circularProgressClasses} />
                    </LoadingWrap>
                ) : null}

                <span>{children}</span>
            </MuiButton>
        );
    },
);

export { Button };
