const fontWeight = {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
};

export type FontWeightTokens = keyof typeof fontWeight;

export { fontWeight };
