export const borderRadius = {
    none: '0px',
    xs: '2px',
    sm: '4px',
    md: '6px',
    lg: '8px',
    xl: '12px',
    xxl: '16px',
};

export type BorderRadiusTokens = keyof typeof borderRadius;
