export type SpacingKeys = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const spacing: Record<SpacingKeys, string> = {
    none: '0',
    xs: '0.375rem',
    sm: '0.625rem',
    md: '1rem',
    lg: '1.625rem',
    xl: '2.625rem',
    xxl: '4.25rem',
};

export { spacing };
