import { gql } from '@apollo/client';

export const GetCurrentTenantQuery = gql`
    query GetCurrentTenant {
        getCurrentTenant {
            id
            companyName
            createdAt
            new_payment_flow_enabled
        }
    }
`;
