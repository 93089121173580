import qs from 'query-string';

class CommonHelper {
    static isSettled(value: any): boolean {
        let isset = true;

        if (Array.isArray(value) && value.filter(Boolean).length === 0) {
            isset = false;
        } else if (
            typeof value === 'object' &&
            (value === null || Object.keys(value).length === 0)
        ) {
            isset = false;
        } else if (!value) {
            isset = false;
        }

        return isset;
    }

    static fillDefaultValues<T extends {}>(values: Record<string, any>, initials: T): T {
        let shallowInitials = { ...initials };

        for (const key in shallowInitials) {
            if (typeof values[key] !== 'undefined') {
                shallowInitials[key] = values[key];
            }
        }

        return shallowInitials;
    }

    static createCountFormatter(number: number, words: string[], withNumber = true) {
        const cases = [2, 0, 1, 1, 1, 2];
        const roundNumber = Math.floor(number);

        const string =
            words[
                roundNumber % 100 > 4 && roundNumber % 100 < 20
                    ? 2
                    : cases[roundNumber % 10 < 5 ? roundNumber % 10 : 5]
            ];

        if (withNumber) {
            const displayNumber = Math.floor(number * 10) / 10;

            return displayNumber + ' ' + string;
        }

        return string;
    }

    static deepSearchParse = (search: string): Record<string, any> => {
        let query = qs.parse(search, { arrayFormat: 'index' });

        for (const key in query) {
            const value = query[key];

            if (Array.isArray(value)) {
                query[key] = value.map(innerValue => {
                    try {
                        return innerValue && JSON.parse(innerValue);
                    } catch {
                        return innerValue;
                    }
                });
            }
        }

        return query;
    };

    static secondsToDHMS(seconds: number): string {
        const d = Math.floor(seconds / (3600 * 24));
        const h = Math.floor((seconds % (3600 * 24)) / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);

        const dd = d > 0 ? ` ${d}d` : '';
        const hh = h > 0 ? ` ${h}h` : '';
        const mm = m > 0 ? ` ${m}m` : '';
        const ss = s > 0 ? ` ${s}s` : '';

        if (dd) {
            return dd + hh;
        }

        if (hh) {
            return dd + hh + mm;
        }

        return dd + hh + mm + ss;
    }
}

export { CommonHelper };
