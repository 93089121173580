import React from 'react';
import type {
    AutocompleteGetTagProps,
    AutocompleteProps as MuiAutocompleteProps,
    AutocompleteRenderInputParams,
} from '@mui/material';
import { Chip } from '@mui/material';
import { Autocomplete as MuiAutocomplete } from '@mui/lab';
import { Close, KeyboardArrowDown } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { TextField } from '@modules/ui/core';
import { concatenateClasses } from '@modules/ui/utils';

const useAutocompleteStyles = makeStyles({
    inputRoot: {
        '&.Mui-error > div:first-child': {
            color: 'inherit',
        },
    },
});

type AutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> = Omit<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
    label: string;
    required?: boolean;
    placeholder?: string;
    error?: boolean;
    helperText?: any;
    renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
};
const baseCloseIcon = <Close style={{ fontSize: 16 }} />;
const basePopupIcon = <KeyboardArrowDown style={{ fontSize: 16 }} />;

const Autocomplete = <
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>(
    props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
): React.ReactElement => {
    const {
        classes,
        required,
        label,
        placeholder,
        value,
        error,
        helperText,
        clearIcon,
        popupIcon,
        getOptionLabel,
        isOptionEqualToValue,
        renderInput,
        renderTags,
        ...otherProps
    } = props;

    const resultClasses = concatenateClasses(useAutocompleteStyles(), classes);

    const issetValue = Array.isArray(value) ? value.length !== 0 : !!value;

    const baseOptionSelected = React.useCallback(
        (option: any, value: any) => option?.id === value?.id,
        [],
    );

    const baseRenderInput = (params: AutocompleteRenderInputParams): React.ReactNode => (
        <TextField
            variant='outlined'
            required={required}
            label={label}
            placeholder={issetValue ? undefined : placeholder}
            error={error}
            helperText={helperText}
            {...params}
        />
    );

    const baseRenderTags = (value: T[], getTagProps: AutocompleteGetTagProps): React.ReactNode =>
        value.map((option, index) => {
            const tagProps = getTagProps({ index });
            const tagLabel = getOptionLabel?.(option) ?? '';

            return <Chip label={tagLabel} {...tagProps} />;
        });

    return (
        <MuiAutocomplete
            classes={resultClasses}
            value={value}
            clearIcon={clearIcon ?? baseCloseIcon}
            popupIcon={popupIcon ?? basePopupIcon}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue ?? baseOptionSelected}
            renderInput={renderInput ?? baseRenderInput}
            renderTags={renderTags ?? baseRenderTags}
            {...otherProps}
        />
    );
};

export { Autocomplete };
