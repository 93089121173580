import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { makeStyles } from '@mui/styles';

import { orange } from '@modules/ui/colors';
import type { DialogProps } from '@modules/ui/core';
import { Dialog, TextField } from '@modules/ui/core';

type ConfirmDialogProps = Omit<DialogProps, 'open' | 'onOpen' | 'onClose'> & {
    confirmText?: string;
};

const useButtonStyles = makeStyles({
    root: {
        backgroundColor: orange[100],

        '&:hover': {
            backgroundColor: darken(0.05)(orange[100]),
        },
    },
});

const ConfirmRow = styled.div`
    width: 100%;

    > p {
        margin-bottom: 14px;
    }
`;

const ConfirmDialog = (props: ConfirmDialogProps): React.ReactElement => {
    const { title, confirmText = 'CANCEL', ...otherProps } = props;

    const buttonClasses = useButtonStyles();

    const [open, setOpen] = React.useState<boolean>(false);

    const handleOpen = (): void => setOpen(true);
    const handleClose = (): void => setOpen(false);

    const [value, setValue] = React.useState<string>('');

    const handleChangeField = (event: React.ChangeEvent<{ value: string }>) =>
        setValue(event.target.value);

    const confirmationContent = `To confirm, type <b>${confirmText}</b> in the text input field`;

    React.useEffect(() => {
        if (!open) {
            setValue('');
        }
    }, [open]);

    const renderedContent = React.useMemo((): React.ReactElement => {
        return (
            <ConfirmRow>
                <p dangerouslySetInnerHTML={{ __html: confirmationContent }} />

                <TextField
                    fullWidth
                    label='Confirm'
                    placeholder='CANCEL'
                    value={value}
                    onChange={handleChangeField}
                />
            </ConfirmRow>
        );
    }, [value, confirmationContent]);

    const disabledAccept = value !== 'CANCEL';

    return (
        <Dialog
            title={title}
            open={open}
            content={renderedContent}
            disabledAccept={disabledAccept}
            onOpen={handleOpen}
            onClose={handleClose}
            CancelButtonProps={{
                variant: 'text',
                color: 'inherit',
            }}
            AcceptButtonProps={{
                classes: buttonClasses,
            }}
            {...otherProps}
        />
    );
};

export { ConfirmDialog };
