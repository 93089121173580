import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { DatepickerContext } from './context';
import type { UseModifyDatepickerProps } from './hooks';
import { useModifyDatepicker } from './hooks';
import { Month } from './month';

type DatepickerProps = UseModifyDatepickerProps & { children?: ReactNode };

const Root = styled.div<{ columns: Required<DatepickerProps['numberOfMonths']> }>`
    display: grid;
    grid-template-columns: ${p => `repeat(${p.columns}, 280px)`};
    grid-gap: 0 16px;
    font-size: 10px;

    @media (max-width: 550px) {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
`;

const Datepicker = React.forwardRef<any, DatepickerProps>((props, ref) => {
    const { numberOfMonths, children, ...otherProps } = props;

    const {
        firstDayOfWeek,
        activeMonths,
        isDateSelected,
        isDateHovered,
        isStartEqualLastDate,
        isStartSelectedDate,
        isLastSelectedDate,
        isFirstOrLastSelectedDate,
        isDateBlocked,
        isDateFocused,
        focusedDate,
        onDateHover,
        onDateSelect,
        onDateFocus,
        onResetDates,
        goToPreviousMonths,
        goToNextMonths,
        goToPreviousYear,
        goToNextYear,
    } = useModifyDatepicker({ numberOfMonths, ...otherProps });

    React.useImperativeHandle(ref, () => ({
        onDateSelect: (date: Date) => {
            onDateSelect(date);
        },
        onResetDates: () => {
            onResetDates();
        },
    }));

    return (
        <DatepickerContext.Provider
            value={{
                focusedDate,
                isDateFocused,
                isDateSelected,
                isDateHovered,
                isDateBlocked,
                isStartEqualLastDate,
                isStartSelectedDate,
                isLastSelectedDate,
                isFirstOrLastSelectedDate,
                onDateSelect,
                onDateFocus,
                onDateHover,
            }}
        >
            <Root ref={ref} columns={numberOfMonths}>
                {activeMonths.map(month => (
                    <Month
                        key={`${month.year}-${month.month}`}
                        year={month.year}
                        month={month.month}
                        firstDayOfWeek={firstDayOfWeek}
                        goToPreviousMonths={goToPreviousMonths}
                        goToNextMonths={goToNextMonths}
                        goToPreviousYear={() => goToPreviousYear(1)}
                        goToNextYear={() => goToNextYear(1)}
                    />
                ))}
            </Root>
        </DatepickerContext.Provider>
    );
});

export { Datepicker };
