import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';
import { TenantPublic } from '@modules/types/graphql';
import { isLowLevelEnv } from '@config/environment';

export type Entity = Omit<TenantPublic, '__typename'>;

@DescribeEntity('TenantBaseEntity')
class TenantBaseEntity extends BaseEntity {
    id: string;
    companyName: string;
    createdAt: string;
    newPaymentFlowEnabled: boolean;

    constructor(tenant: Entity) {
        super(tenant);

        this.id = tenant.id;
        this.companyName = tenant.companyName;
        this.createdAt = tenant.createdAt;
        this.newPaymentFlowEnabled = !!tenant?.new_payment_flow_enabled;
    }

    get isFundsManagementEnabled() {
        return this.newPaymentFlowEnabled && isLowLevelEnv;
    }
}

export { TenantBaseEntity };
