import React from 'react';
import styled from 'styled-components';
import { Button as BaseButton, ButtonProps } from '@modules/ui/core';

type Props = ButtonProps & {
    // extend if required
};

const Button = styled(BaseButton)`
    color: ${({ theme }) => theme.palette.text.secondary};
`;

const CancelButton: React.FunctionComponent<Props> = props => {
    const { children = 'Cancel', ...rest } = props;

    return (
        <Button variant='text' {...rest}>
            {children}
        </Button>
    );
};

export { CancelButton };
