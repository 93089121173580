import { Stack, Typography } from '@mui/material';
import { ThemeProvider } from '@modules/shared/theme-provider';
import { spacing } from '@modules/ui/tokens';
import { SystemHelper } from '@helpers';
import { ImageLogo } from './choicepay-home-page.styled';

export function ChoicePayHomePage() {
    const currentYear = new Date().getFullYear();
    const logoPath = SystemHelper.isDev() ? '/images/dev-logo.svg' : '/theme/choicepay/logo.svg';

    return (
        <ThemeProvider>
            <Stack
                width='100%'
                height='100vh'
                gap={spacing.md}
                justifyContent='center'
                alignItems='center'
            >
                <ImageLogo src={logoPath} alt='Choicepay logo' />
                <Typography variant='body1' textAlign='center'>
                    Third-party product, logos, service and business names are trademarks/service
                    marks of their respective owners.
                </Typography>
                <Typography variant='body1' textAlign='center'>
                    {`© ${currentYear} Powered by ChoicePay. All rights reserved.`}
                </Typography>
            </Stack>
        </ThemeProvider>
    );
}
