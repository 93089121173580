import React, { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
    children?: ReactNode;
};

const Root = styled.div`
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing('md')};
    text-align: right;

    > *:not(:last-child) {
        margin-right: ${({ theme }) => theme.spacing('md')};
    }
`;

const Footer: React.FunctionComponent<Props> = props => {
    const { children } = props;

    return <Root>{children}</Root>;
};

export { Footer };
