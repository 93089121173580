import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';
import { spacing, fontSize, fontWeight, borderRadius } from '@modules/ui/tokens';
import { white } from '@modules/ui/colors';
import {
    RadioButtonChecked,
    RadioButtonUnchecked,
    CheckBox,
    CheckBoxOutlineBlank,
    IndeterminateCheckBox,
    ExpandMore,
} from '@mui/icons-material';
import { typography } from './typography';
import { palette } from './palette';

const radioCheckedIcon = <RadioButtonChecked style={{ fontSize: 24 }} />;
const radioIcon = <RadioButtonUnchecked style={{ fontSize: 24 }} />;
const checkedIcon = <CheckBox style={{ fontSize: 20 }} />;
const icon = <CheckBoxOutlineBlank style={{ fontSize: 20 }} />;
const indeterminateIcon = <IndeterminateCheckBox style={{ fontSize: 20 }} />;
const expandMoreIcon = <ExpandMore style={{ fontSize: 24 }} />;

export const components: Components<Omit<Theme, 'components'>> = {
    MuiAccordionSummary: {
        styleOverrides: {
            root: {
                padding: `${spacing.sm} ${spacing.md}`,
            },
            content: {
                margin: 0,
                '&.Mui-expanded': {
                    margin: 0,
                },
            },
        },
        defaultProps: {
            expandIcon: expandMoreIcon,
        },
    },

    MuiTextField: {
        defaultProps: {
            variant: 'outlined',
        },
    },

    MuiRadio: {
        defaultProps: {
            checkedIcon: radioCheckedIcon,
            icon: radioIcon,
        },
    },

    MuiCheckbox: {
        defaultProps: {
            color: 'primary',
            checkedIcon,
            icon,
            indeterminateIcon,
        },
    },

    MuiBreadcrumbs: {
        styleOverrides: {
            root: {
                ...typography(palette).body2,
            },
        },
    },

    MuiButton: {
        defaultProps: {
            variant: 'contained',
            color: 'primary',
            size: 'medium',
        },
        styleOverrides: {
            root: {
                padding: `${spacing.sm} ${spacing.md}`,
                boxSizing: 'border-box',
                whiteSpace: 'nowrap',
            },
        },
    },

    MuiIconButton: {
        defaultProps: {
            size: 'small',
        },
    },

    MuiTooltip: {
        defaultProps: {
            placement: 'top-start',
        },
        styleOverrides: {
            tooltip: {
                fontSize: fontSize.sm,
                backgroundColor: palette.common?.white,
                color: palette.text?.primary,
                padding: `${spacing.sm} ${spacing.md}`,
                boxShadow: `0px 0px 1px 0px #0000004F, 0px 0px 14px -2px #00000026`,
                border: `1px solid #ECEEF0`,
                margin: 0,
            },
            popper: {
                margin: 0,
            },
        },
    },

    MuiFormControlLabel: {
        styleOverrides: {
            root: {
                marginRight: 0,
            },
            label: {
                fontWeight: fontWeight.semibold,
            },
        },
    },

    MuiInputBase: {
        styleOverrides: {
            root: {
                fontSize: fontSize.md,
                height: 'auto',
            },
        },
    },

    MuiOutlinedInput: {
        styleOverrides: {
            input: {
                padding: spacing.md,
                height: 'auto',
                lineHeight: 1.5,
            },
            inputMultiline: {
                padding: 0,
                height: 'auto',
                lineHeight: 1.5,
            },
            multiline: {
                height: 'auto',
                lineHeight: 1.5,
            },
        },
    },

    MuiFormHelperText: {
        styleOverrides: {
            root: {
                margin: 0,
                '&.Mui-error': {
                    fontSize: fontSize.xs,
                    padding: `0 ${spacing.md}`,
                    lineHeight: 1,
                    margin: 0,
                    position: 'absolute',
                    bottom: '-20px',
                    minHeight: '20px',
                },
            },
            contained: {
                marginLeft: 0,
            },
        },
    },

    MuiLink: {
        styleOverrides: {
            root: {
                ...typography(palette).body1,
                color: palette.text?.primary,
            },
        },
    },

    MuiSelect: {
        styleOverrides: {
            select: {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                fontSize: fontSize.md,

                '&$selectMenu': {
                    lineHeight: '20px',
                },
            },
            icon: {
                top: 'calc(50% - 14px)',
            },
            iconOutlined: {
                right: 8,
            },
        },
    },

    MuiChip: {
        styleOverrides: {
            root: {
                fontSize: fontSize.sm,
                fontWeight: fontWeight.semibold,
                lineHeight: 1.23,
                borderRadius: `${borderRadius.xxl}`,
                padding: `${spacing.xs} ${spacing.sm}`,
            },
        },
    },

    MuiTabs: {
        styleOverrides: {
            root: {
                borderBottom: `1px solid ${white[80]}`,
                margin: 0,
            },
        },
    },

    MuiTab: {
        styleOverrides: {
            root: {
                maxWidth: 320,
                padding: `${spacing.md} ${spacing.xl}`,
                fontSize: fontSize.md,
                fontWeight: 600,
                textTransform: 'none',
            },
            selected: {},
        },
    },

    MuiAccordion: {
        styleOverrides: {
            root: {
                boxShadow: '0 1px 2px rgba(63, 63, 68, 0.1)',
            },
        },
    },

    MuiAccordionDetails: {
        styleOverrides: {
            root: {
                padding: '0 20px 24px 20px',
            },
        },
    },

    MuiMenuItem: {
        styleOverrides: {
            root: {
                fontSize: fontSize.md,
            },
        },
    },

    MuiTableCell: {
        styleOverrides: {
            root: {
                ...typography(palette).body1,
                padding: `${spacing.md} ${spacing.lg}`,
            },
        },
    },

    MuiTablePagination: {
        styleOverrides: {
            toolbar: {
                display: 'flex',
                gap: spacing.lg,
                minHeight: 'auto',
                height: 'auto',
                '@media (min-width: 600px)': {
                    minWidth: 0,
                    minHeight: 0,
                },
            },
            selectRoot: {
                margin: 0,
            },
            select: {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                fontSize: fontSize.md,
                '&:focus': {
                    borderRadius: 4,
                },
            },
            selectIcon: {},
            displayedRows: {
                fontSize: fontSize.md,
                fontWeight: 400,
                margin: 0,
            },
            selectLabel: {
                fontSize: fontSize.md,
                fontWeight: 400,
                margin: 0,
            },
            actions: {
                margin: 0,
                display: 'flex',
                gap: spacing.md,
                '& svg': {
                    fontSize: fontSize.md,
                },

                '& buttons': {
                    padding: 0,
                },
            },
        },
    },
};
