import React from 'react';
import MaskedInput from 'react-text-mask';

import { getMask } from './get-mask';

import type { GetMaskProps } from './get-mask';

export const placeholderChar = '\u2000';

type DateTextMaskProps = Pick<GetMaskProps, 'dateFormat'> & {
    inputRef: (ref: any) => void;
    variant?: 'single' | 'range';
};

const DateTextMask = (props: DateTextMaskProps): React.ReactElement => {
    const { inputRef, variant = 'single', dateFormat, ...other } = props;

    const mask = getMask({ variant, dateFormat });

    const handleSetRef = (ref: any) => {
        inputRef(ref ? ref.inputElement : null);
    };

    return (
        <MaskedInput {...other} ref={handleSetRef} mask={mask} placeholderChar={placeholderChar} />
    );
};

export { DateTextMask };
