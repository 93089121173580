import React from 'react';
import styled from 'styled-components';
import type { DrawerProps as MuiDrawerProps } from '@mui/material';
import { Drawer as MuiDrawer, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { concatenateClasses } from '@modules/ui/utils';
import { spacing } from '@modules/ui/tokens';

export type DrawerProps = Omit<MuiDrawerProps, 'onClose'> & {
    title: string;
    onClose: () => void;
};

const useDrawerStyles = makeStyles(theme => ({
    paper: {
        maxWidth: 420,
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
}));

const Root = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ${spacing.md};
    border-bottom: 1px solid #e3e5e7;
`;

const CloseHandler = styled.div`
    opacity: 0.5;
    transition: opacity 0.2s;
    cursor: pointer;
    margin: 0;
    line-height: 1;
    display: flex;

    &:hover {
        opacity: 0.8;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${spacing.md};
`;

const closeIcon = <Close style={{ fontSize: 24 }} />;

const Drawer = (props: DrawerProps): React.ReactElement => {
    const { title, classes, onClose, anchor = 'right', children, ...otherProps } = props;

    const drawerClasses = concatenateClasses(useDrawerStyles(), classes);

    return (
        <MuiDrawer anchor={anchor} classes={drawerClasses} onClose={onClose} {...otherProps}>
            <Root>
                <Header>
                    <Typography variant='h2'>{title}</Typography>
                    <CloseHandler onClick={onClose}>{closeIcon}</CloseHandler>
                </Header>

                <Body>{children}</Body>
            </Root>
        </MuiDrawer>
    );
};

export { Drawer };
