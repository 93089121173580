import React from 'react';
import styled from 'styled-components';
import type { ModalProps as MuiModalProps } from '@mui/material';
import { Modal as MuiModal, Fade, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { concatenateClasses } from '@modules/ui/utils';
import { spacing } from '@modules/ui/tokens';

export type ModalProps = Omit<MuiModalProps, 'children' | 'onClose'> & {
    children: React.ReactNode;
    onClose: () => void;
    hideCloseIcon?: boolean;
    title?: string;
};

const useModalStyles = makeStyles(({ palette, breakpoints }) => ({
    container: {
        position: 'relative',
        width: 520,
        backgroundColor: palette.common?.white,
        borderRadius: 4,
        marginTop: '5%',

        [breakpoints.down('sm')]: {
            maxWidth: '90%',
            margin: '6% auto 0',
        },
    },
}));

const ModalRoot = styled(MuiModal)`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;

    > * {
        outline: none;
    }
`;

const Container = styled.div`
    padding: ${spacing.lg};
    display: flex;
    flex-direction: column;
    gap: ${spacing.md};
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        height: auto;
        min-height: 56px;
    }
`;

const CloseTrigger = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: ${spacing.md};
    right: ${spacing.md};
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.2s;

    &:hover {
        opacity: 1;
    }
`;

const Body = styled.div`
    display: flex;
    width: 100%;
`;

const closeIcon = <Close style={{ fontSize: 24 }} />;

const Modal = (props: ModalProps): React.ReactElement => {
    const { title, classes, hideCloseIcon, open, children, onClose, ...otherProps } = props;

    const { container, ...modalClasses } = concatenateClasses(useModalStyles(), classes);

    const showHeader = !!title || !hideCloseIcon;

    return (
        <ModalRoot
            closeAfterTransition
            classes={modalClasses}
            open={open}
            onClose={onClose}
            {...otherProps}
        >
            <Fade in={open}>
                <Container className={container}>
                    {showHeader ? (
                        <Header>
                            {title ? <Typography variant='h1'>{title}</Typography> : null}
                            {hideCloseIcon ? null : (
                                <CloseTrigger onClick={onClose}>{closeIcon}</CloseTrigger>
                            )}
                        </Header>
                    ) : null}

                    <Body>{children}</Body>
                </Container>
            </Fade>
        </ModalRoot>
    );
};

export { Modal };
