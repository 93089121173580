import React from 'react';
import styled, { css } from 'styled-components';
import { format, startOfToday } from 'date-fns';
import { useMonth } from '@datepicker-react/hooks';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { DatepickerContext } from '../context';
import { Day } from '../day';

import type { UseModifyDatepickerReturn } from '../hooks';

type MonthProps = Pick<UseModifyDatepickerReturn, 'goToPreviousMonths' | 'goToNextMonths'> & {
    year: number;
    month: number;
    goToPreviousYear: () => ReturnType<UseModifyDatepickerReturn['goToPreviousYear']>;
    goToNextYear: () => ReturnType<UseModifyDatepickerReturn['goToNextYear']>;
    firstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
};

const Root = styled.div`
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.background.paper};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 8px;
    color: ${({ theme }) => theme.palette.text.primary};
    border-bottom: 1px solid ${({ theme }) => theme.palette.text.primary};
`;

const HeaderTitle = styled.div`
    margin: 0 auto;
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 1.4em;
    font-weight: 500;
    line-height: 24px;
`;

const HeaderToggle = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.palette.text.primary};
    transition: color 0.2s;
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.palette.primary.dark};
    }
`;

const DoubleIcon = styled.div<{ direction: 'left' | 'right' }>`
    position: relative;
    width: 24px;
    height: 24px;

    ${p =>
        (p.direction === 'left' &&
            css`
                > * {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;

                    &:last-child {
                        left: 5px;
                    }
                }
            `) ||
        (p.direction === 'right' &&
            css`
                > * {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;

                    &:last-child {
                        left: 5px;
                    }
                }
            `)}
`;

const WeekdayGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-content: center;
    padding: 8px 12px;
    font-size: 1.4em;
`;

const WeekdayLabel = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 30px;
    color: ${({ theme }) => theme.palette.text.primary};
`;

const Footer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    padding: 0 8px;
    border-top: 1px solid ${({ theme }) => theme.palette.text.primary};

    > span {
        color: ${({ theme }) => theme.palette.text.primary};
        cursor: pointer;
    }
`;

const chevronLeftIcon = <ChevronLeft style={{ fontSize: 20 }} />;
const chevronRightIcon = <ChevronRight style={{ fontSize: 20 }} />;

const doubleChevronLeftIcon = (
    <DoubleIcon direction='left'>
        <ChevronLeft style={{ fontSize: 20 }} />
        <ChevronLeft style={{ fontSize: 20 }} />
    </DoubleIcon>
);

const doubleChevronRightIcon = (
    <DoubleIcon direction='right'>
        <ChevronRight style={{ fontSize: 20 }} />
        <ChevronRight style={{ fontSize: 20 }} />
    </DoubleIcon>
);

const Month = (props: MonthProps): React.ReactElement => {
    const {
        year,
        month,
        firstDayOfWeek,
        goToPreviousMonths,
        goToPreviousYear,
        goToNextMonths,
        goToNextYear,
    } = props;

    const { onDateSelect } = React.useContext(DatepickerContext);

    const { monthLabel, weekdayLabels, days } = useMonth({
        year,
        month,
        firstDayOfWeek,
        dayLabelFormat: date => format(date, 'd'),
        weekdayLabelFormat: date => format(date, 'E'),
        monthLabelFormat: date => format(date, 'LLL yyyy'),
    });

    const handleClickSetToday = (): void => {
        onDateSelect(startOfToday());
    };

    return (
        <Root>
            <Header>
                <HeaderToggle onClick={goToPreviousYear}>{doubleChevronLeftIcon}</HeaderToggle>
                <HeaderToggle onClick={goToPreviousMonths}>{chevronLeftIcon}</HeaderToggle>

                <HeaderTitle>{monthLabel}</HeaderTitle>

                <HeaderToggle onClick={goToNextMonths}>{chevronRightIcon}</HeaderToggle>
                <HeaderToggle onClick={goToNextYear}>{doubleChevronRightIcon}</HeaderToggle>
            </Header>

            <WeekdayGrid>
                {weekdayLabels.map(weekdayLabel => (
                    <WeekdayLabel key={weekdayLabel}>{weekdayLabel}</WeekdayLabel>
                ))}
            </WeekdayGrid>

            <WeekdayGrid>
                {days.map((day, index) => {
                    if (typeof day === 'object') {
                        return <Day key={String(day.date)} date={day.date} label={day.dayLabel} />;
                    }

                    return <div key={index} />;
                })}
            </WeekdayGrid>

            <Footer>
                <span onClick={handleClickSetToday}>Today</span>
            </Footer>
        </Root>
    );
};

export { Month };
