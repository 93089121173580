import type { QueryHookOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { GetCurrentTenantQuery } from '@modules/shared/graphql';
import type {
    GetCurrentTenantQueryType,
    GetCurrentTenantQueryVariables,
} from '@modules/types/graphql';
import { TenantBaseEntity } from '@modules/shared/entities';

const useCurrentTenant = (
    options: QueryHookOptions<GetCurrentTenantQueryType, GetCurrentTenantQueryVariables> = {},
) => {
    const queryResult = useQuery<GetCurrentTenantQueryType, GetCurrentTenantQueryVariables>(
        GetCurrentTenantQuery,
        options,
    );

    const currentTenant = useEntity(
        () =>
            queryResult.data?.getCurrentTenant
                ? new TenantBaseEntity(queryResult.data.getCurrentTenant)
                : null,
        [queryResult.data],
    );

    const result = {
        currentTenant,
        ...queryResult,
    };

    return result;
};

export { useCurrentTenant };
